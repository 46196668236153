export default {
	email: "info@stageandscreentech.co.uk",
	phone: "+44 (0) 7465 440235",
	socials: {
		instagram: {
			url: "https://www.instagram.com/stageandscreentech",
			handle: "stageandscreentech"
		},
		threads: {
			url: "https://www.threads.net/@stageandscreentech",
			handle: "stageandscreentech"
		},
		youtube: {
			url: "https://www.youtube.com/@stageandscreentech",
			handle: "stageandscreentech"
		},
		twitter: {
			url: "https://twitter.com/stagescreentech",
			handle: "stagescreentech"
		}
	}
};
